<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backleads">mdi-reply</v-icon>

        {{ "Leads" }}
      </div>
    </div>
    <v-card color="white" elevation="1">
      <template v-if="lead">
        <v-card-title class="secondary white--text" primary-title>
          Lead Details
          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon color="primary" @click="goEdit()"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>

            <span>Edit Lead</span>
          </v-tooltip>

          <template v-if="isAdmin || isSuper">
            <asign-to
              :color="'success'"
              @assigned="getLeadData"
              :leadUuid="lead.uuid"
            />
          </template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                v-on="on"
                icon
                class="mx-1"
                color="primary"
                @click="convertToPatient()"
                dark
                ><v-icon>mdi-account-convert</v-icon></v-btn
              >
            </template>
            <span>Convert to {{ getpatientN }}</span>
          </v-tooltip>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  v-on="on"
                  icon
                  :disabled="dialogmakeCall"
                  @click="openCall()"
                >
                  <v-icon>mdi-phone-dial</v-icon>
                </v-btn>
              </template>
              <span>Make Call</span>
            </v-tooltip>

            <!--  <btn-make-call
              :lead="lead.uuid"
              :external="true"
              :numberPatient="lead.phone"
            /> -->
          </template>

          <send-email :color="'warning'" :user="lead" />
          <template v-if="lead != null && lead != undefined">
            <!-- <drawer-sms
              :leadUuid="lead.uuid"
              :color="'error'"
              :details="lead"
              :origin="'lead'"
              @updateFiles="updateFiles"
            /> -->
            <template v-if="getMethodCalls == 'twilio'">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="error"
                    :disabled="showSmsGlobal"
                    icon
                    @click="openSMS"
                    ><v-icon>mdi-android-messages</v-icon></v-btn
                  >
                </template>
                <span>Send Message</span>
              </v-tooltip>
            </template>
          </template>
          <template v-if="getsendQ">
            <btn-create-quote :leadid="lead.uuid" @created="getLeadData" />
          </template>

          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="info" v-on="on" icon @click="getLeadData"
                  ><v-icon>mdi-reload</v-icon></v-btn
                >
              </template>
              <span>Details Reload</span>
            </v-tooltip>
          </div>
        </v-card-title>
        <v-tabs v-model="tab" centered icons-and-text grow show-arrows>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#details">
            Details
            <v-icon>mdi-clipboard-account</v-icon>
          </v-tab>

          <v-tab href="#medical">
            Medical History
            <v-icon>mdi-heart</v-icon>
          </v-tab>
          <v-tab href="#relations">
            Relationship
            <v-icon>mdi-account-network</v-icon>
          </v-tab>
          <template v-if="getsendQ">
            <v-tab href="#quotes">
              Quotes
              <v-icon>mdi-clipboard-text</v-icon>
            </v-tab>
          </template>
          <v-tab href="#calls">
            Calls
            <v-icon>mdi-phone </v-icon>
          </v-tab>

          <v-tab href="#notes">
            Notes
            <v-icon>mdi-note-text </v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-container>
            <v-tab-item value="details">
              <v-card flat>
                <v-card-text>
                  <div class="d-flex flex-row my-2">
                    <div class="col-sm-4">
                      <p class="mb-2">Id: {{ lead.id }}</p>
                      <br />
                      <p class="mb-2">Name: {{ lead.name }}</p>
                      <br />
                      <p class="mb-2">Last Name: {{ lead.last_name }}</p>
                      <br />
                      <p class="mb-2">
                        Procedure of interest:
                        {{
                          lead.procedureInterest
                            ? lead.procedureInterest
                            : "None"
                        }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="mb-2">Email: {{ lead.email }}</p>
                      <br />
                      <p class="mb-2">
                        Phone:
                        {{
                          lead.phone == null || lead.phone == undefined
                            ? "-"
                            : lead.phone
                        }}
                      </p>
                      <br />
                      <p class="mb-2">
                        Source:
                        <template
                          v-if="
                            lead.howKnow != null && lead.howKnow != undefined
                          "
                        >
                          {{
                            lead.howKnow == null || lead.howKnow == undefined
                              ? "-"
                              : lead.howKnow
                          }}
                        </template>
                      </p>
                      <br />
                      <p class="mb-2">
                        Doctor of choice:
                        {{ getDoctorName() }}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="mb-2">
                        Status:
                        <v-menu offset-x>
                          <template v-slot:activator="{ on }">
                            <span
                              v-on="on"
                              class="font-weight-bold cursor-pointer"
                              v-bind:style="{ color: colorShow() }"
                              >{{ statusShow() }}</span
                            >
                          </template>
                          <v-list>
                            <v-list-item
                              class="cursor-pointer"
                              v-for="(st, index) in status"
                              :key="index"
                              link
                              :disabled="statusShowReal() == st.name"
                              @click="changeStatus(st)"
                            >
                              <v-list-item-title
                                :class="
                                  statusShowReal() == st.name ? 'red--text' : ''
                                "
                                >{{ st.name }}</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </p>
                      <br />
                      <p class="mb-2">
                        Gender: {{ lead.isMale ? "Male" : "Female" }}
                      </p>
                      <br />

                      <p class="mb-2">
                        Childrens: {{ lead.children ? "Yes" : "No" }}
                      </p>
                    </div>
                  </div>

                  <v-divider class="mx-2 my-1"></v-divider>
                  <v-row>
                    <v-col>
                      <all-images
                        ref="images"
                        @uploaded="getLeadData"
                        :imagesfrom="'lead'"
                        :uuid="lead.uuid"
                        :imagesProps="lead.pictures"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="medical">
              <v-card flat>
                <v-card-text>
                  <v-divider class="mx-2"></v-divider>
                  <div class="d-flex justify-center">
                    <h4>Medical History</h4>
                  </div>
                  <v-divider class="mx-2 my-1"></v-divider>

                  <v-row justify="start" class="px-4">
                    <v-col cols="12" sm="3">
                      <strong>DOB:</strong> {{ formatDate() }}
                    </v-col>
                    <v-col cols="12" sm="3">
                      <strong>Weight:</strong> {{ lead.weight + " lbs" }}
                    </v-col>
                    <v-col cols="12" sm="2">
                      <strong>Height:</strong> {{ getHeight(lead.height) }}
                    </v-col>
                    <v-col cols="12" sm="2">
                      <strong>BMI:</strong> {{ bmi }}
                    </v-col>
                    <v-col cols="12" sm="2">
                      <strong :class="colorWeight + '--text'">{{
                        typebmi
                      }}</strong>
                    </v-col>
                  </v-row>
                  <v-divider class="my-1 texts mx-2"></v-divider>
                  <template v-if="lead.medicalHistory != null">
                    <v-row justify="center" class="px-4">
                      <v-col
                        ><strong>Smoking:</strong>
                        {{ lead.medicalHistory.smoking ? "Yes" : "No" }}
                      </v-col>
                      <v-col
                        ><strong>Alcohol:</strong>
                        {{ lead.medicalHistory.alcohol ? "Yes" : "No" }}
                      </v-col>
                      <v-col
                        ><strong>Other Substances:</strong>
                        {{
                          lead.medicalHistory.otherSubstances
                            ? lead.medicalHistory.otherSubstances
                            : "None"
                        }}
                      </v-col>
                    </v-row>
                    <v-divider class="my-1 mx-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col cols="12" sm="6">
                        <strong>Procedure of interest:</strong>
                        {{
                          lead.procedureInterest
                            ? lead.procedureInterest
                            : "None"
                        }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <strong>Doctor of choice:</strong>
                        {{ getDoctorName() }}
                      </v-col>
                    </v-row>
                    <v-divider class="my-1 texts mx-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col cols="12" sm="6">
                        <strong>Medical Condition:</strong>
                        {{
                          lead.medicalHistory.medicalCondition
                            ? lead.medicalHistory.medicalCondition
                            : "None"
                        }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <strong>Alergies:</strong>
                        {{
                          lead.medicalHistory.alergies
                            ? lead.medicalHistory.alergies
                            : "None"
                        }}
                      </v-col>
                    </v-row>
                    <v-divider class="my-1 texts mx-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col cols="12" sm="6">
                        <strong>Previous surgeries:</strong>
                        {{
                          lead.medicalHistory.previosSurgery
                            ? lead.medicalHistory.previosSurgery
                            : "None"
                        }}</v-col
                      >
                      <v-col cols="12" sm="6">
                        <strong>Current medications:</strong>
                        {{
                          lead.medicalHistory.currentMedications
                            ? lead.medicalHistory.currentMedications
                            : "None"
                        }}</v-col
                      >
                    </v-row>
                    <v-divider class="mx-2 my-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col cols="12" sm="6">
                        <strong>Blood Clots or Clotting:</strong>
                        {{
                          lead.medicalHistory.bloodClotsOrClotting
                            ? lead.medicalHistory.bloodClotsOrClotting
                            : "None"
                        }}</v-col
                      >
                      <v-col cols="12" sm="6">
                        <strong>Butt Shots Injections:</strong>
                        {{
                          lead.medicalHistory.buttShotsInjections ? "Yes" : "No"
                        }}</v-col
                      >
                    </v-row>
                    <v-divider class="mx-2 my-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col cols="12" sm="6">
                        <strong>Desired Surgery Date: </strong>
                        <template v-if="desireSurgerySelect != null">
                          <v-btn
                            dark
                            rounded
                            small
                            :color="desireSurgerySelect.color"
                            >{{ desireSurgerySelect.label }}</v-btn
                          >
                        </template>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <strong>Details:</strong>
                        {{
                          lead.medicalHistory.details
                            ? lead.medicalHistory.details
                            : "None"
                        }}</v-col
                      >
                    </v-row>
                    <v-divider class="mx-2 my-2"></v-divider>
                    <v-row justify="center" class="px-4">
                      <v-col>
                        <strong>Best Time to Call:</strong>
                        {{
                          lead.medicalHistory.bestTimeToCall
                            ? lead.medicalHistory.bestTimeToCall
                            : "None"
                        }}</v-col
                      >
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="relations"
              ><lead-relation :lead="lead" @getData="getLeadData" />
            </v-tab-item>
            <template v-if="getsendQ">
              <v-tab-item value="quotes">
                <lead-quotes :quotesProps="lead.quotes" />
              </v-tab-item>
            </template>
            <v-tab-item value="calls">
              <v-card flat>
                <v-card-text>
                  <template
                    v-if="lead.phone != undefined && lead.phone != null"
                  >
                    <v-card>
                      <v-toolbar dark color="secondary">
                        <v-toolbar-title> Calls </v-toolbar-title>

                        <!--  Revisar Calls -->
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="white"
                              dark
                              @click="expandedLead"
                            >
                              <v-icon>mdi-autorenew</v-icon></v-btn
                            >
                          </template>
                          <span>Refresh Calls</span>
                        </v-tooltip>
                      </v-toolbar>
                      <v-card-title primary-title> </v-card-title>
                      <v-card-text>
                        <table-calls
                          @descripadd="expandedLead"
                          :lead="lead"
                        ></table-calls>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="notes">
              <v-card flat>
                <v-card-text>
                  <v-card>
                    <v-toolbar dark color="secondary">
                      <v-toolbar-title> Notes </v-toolbar-title>

                      <v-btn
                        depressed
                        class="ml-auto black--text"
                        color="white"
                        dark
                        @click="OpenDialog('note')"
                        >Add Note</v-btn
                      >
                    </v-toolbar>
                    <v-card-title primary-title>
                      <div class="row">
                        <v-dialog v-model="dialogNote" max-width="600">
                          <v-card>
                            <v-card-title primary-title>
                              <h4>Add Note</h4>
                              <v-spacer></v-spacer>
                              <v-switch
                                inset
                                label="Evaluation"
                                v-model="evaluation"
                              ></v-switch>
                            </v-card-title>
                            <v-card-text>
                              <v-textarea
                                outlined
                                name="input-7-4"
                                label="DETAILS"
                                v-model="detailsnote"
                              ></v-textarea
                            ></v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="cancelDialog"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="green darken-1"
                                text
                                @click="addNote"
                              >
                                Add
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <table-leads
                        :data="lead.notes"
                        :headers="'notes'"
                      ></table-leads>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-container>
        </v-tabs-items>
      </template>
      <template v-else-if="lead == null && loading">
        <v-card-text class="d-flex flex-column align-center">
          <strong>Loading...</strong>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-card-text>
      </template>
    </v-card>
  </div>
</template>
<script>
import { desireSurgery } from "@/utils/consts";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import moment from "moment";
import SendEmail from "@/components/emails/SendEmail.vue";
import AllImages from "@/components/Images/AllImages.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import BtnCreateQuote from "@/views/Patients/buttons/BtnCreateQuote.vue";
import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";
import LeadRelation from "@/views/Configurations/components/LeadRelation.vue";

import LeadQuotes from "@/views/Configurations/components/LeadQuotes.vue";
import { notifyError, notifyInfo } from "@/components/Notification";
export default {
  name: "lead-details",
  components: {
    TableLeads,
    TableCalls,
    AllImages,
    BtnMakeCall,
    BtnCreateQuote,
    LeadRelation,
    AsignTo,
    DrawerSms,
    SendEmail,

    LeadQuotes,
  },
  data() {
    return {
      tab: null,
      lead: null,
      desireSurgery,
      loading: false,
      loadingcall: false,
      dialogNote: false,
      evaluation: false,
      detailsnote: "",
      status: [],
    };
  },
  watch: {
    lead(val) {
      if (val != null) {
        this.expandedLead();
      }
    },
  },
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isAdmin",
      "isSuper",
      "getsendQ",
      "getMethodCalls",
      "getMethodCalls",
    ]),

    ...mapState("crmCallCenterModule", ["dialogmakeCall", "callsLead"]),
    ...mapState(["showSmsGlobal"]),
    desireSurgerySelect() {
      if (this.lead == null || this.lead.medicalHistory == null) {
        return null;
      }
      if (this.lead.medicalHistory.desiredSurgeryDate) {
        const t = this.lead.medicalHistory.desiredSurgeryDate;
        const desire = this.desireSurgery.filter((d) => d.value == t);
        return desire[0];
      }
      return null;
    },

    bmi() {
      let inches = 0;
      let feets = 0;
      if (this.lead == null) {
        return 0;
      }
      if (this.lead.weight == 0 && this.lead.height == 0) return 0;
      const he = this.lead.height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = he.split(".")[0] ? Number(he.split(".")[0]) : 0;
      } else if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = he.split(".")[0] ? Number(he.split(",")[0]) : 0;
      } else {
        feets = he;
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number(this.lead.weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    ...mapMutations(["mutShowSms"]),
    openCall() {
      if (this.getMethodCalls == "twilio") {
        localStorage.setItem("number", this.lead.phone);
        this.mutDialogmakeCall(true);
      } else {
        this.loadingcall = true;
        getAPI
          .get("/zoom/zoomPhonecall?phone=" + this.lead.phone)
          .then((res) => {
            window.open(res.data, "_blank", "noreferrer");
            this.loadingcall = false;
          })
          .catch((error) => {
            this.loadingcall = false;
            let mess = error.response.data.message.toString();

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
    openSMS() {
      localStorage.setItem("numberSMS", this.lead.phone);
      localStorage.setItem(
        "nameSMS",
        this.lead.name + " " + this.lead.last_name
      );
      this.mutShowSms(true);
    },

    getLeadData() {
      this.loading = true;
      const leaduuid = this.$route.params.uuid;
      getAPI("/social/getLead/" + leaduuid)
        .then((res) => {
          this.lead = res.data;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    changeStatus(status) {
      this.loading = true;
      let body = {
        leadUuid: this.lead.uuid,
        statusNom: status.id,
      };
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Lead Status has been changed");
          this.loading = false;
          this.getLeadData();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    statusShow() {
      const est = this.lead.statusNom ? this.lead.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    statusShowReal() {
      const est = this.lead.statusNom ? this.lead.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }

      return est;
    },
    colorShow() {
      return this.lead.statusNom ? this.lead.statusNom.color : "#000";
    },

    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    convertToPatient() {
      this.mutleadSelect(this.lead);
      this.$router.push("/patients/create");
    },
    formatDate() {
      const d = moment(this.lead.birthDate).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName() {
      if (
        this.lead.medicalHistory == null ||
        this.lead.medicalHistory == undefined
      ) {
        return "None";
      }
      if (this.lead.medicalHistory.doctorChoice != null) {
        return this.lead.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },

    cancelDialog() {
      this.dialogNote = false;

      this.evaluation = false;

      this.detailsnote = "";
    },

    async addNote() {
      await this.actAddNote({
        details: this.detailsnote,
        leadUuid: this.lead.uuid,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      this.cancelDialog();
      this.getLeadData();
    },

    OpenDialog(type) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.dialogLead = true;
          break;
      }
    },
    backleads() {
      this.$router.push("/leads_center");
    },
    goEdit() {
      localStorage.setItem("from", "lead");
      this.$router.push("/leads_center/edit/" + this.lead.uuid);
    },

    updateFiles() {
      setTimeout(() => {
        this.$refs.images.getImages();
      }, 500);
    },

    expandedLead() {
      const phone = this.lead.phone;
      if (this.getMethodCalls == "twilio") {
        this.actFilterCalls({
          whateverNumber: phone,
          limit: 500,
          offset: 0,
        });
      }
    },
  },
  async mounted() {
    await this.loadLeadStatus();
    await this.getLeadData();
    localStorage.setItem("leadcenter", true);
  },
};
</script>
<style lang="scss">
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}
.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}
.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}
.actions {
  margin-top: -50px !important;
}
.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}
.borderdivider {
  border: 1px solid;
}
.test {
  border: 1px solid red;
}
</style>
